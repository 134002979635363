import { ref } from '@vue/composition-api'
import useLesson from '@/components/js/compositionAPI/lesson/useLesson'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

export default function useCart() {
  const dataList = ref([])
  const total = ref(0)
  const discount = ref(0)
  const { router } = useRouter()
  const {
    categoryA,
  } = useLesson()

  const getSum = () => {
    dataList.value = []
    discount.value = 0
    store.state.payment.cartList.forEach(item => {
      const dic = categoryA.find(v => v.id === Number(item))
      const d = {
        lessonName: dic.labelName,
        price: dic.price,
        id: dic.id,
      }
      dataList.value.push(d)
    })
    if (store.state.payment.cartList.length > 1) {
      const sumvalue = (dataList.value.map(v => v.price).reduce((o, n) => o + n)) / 1.1
      discount.value = Number(sumvalue) / 2
      total.value = Number(sumvalue) - Number(discount.value)
    }
    if (store.state.payment.cartList.length === 1) total.value = dataList.value[0].price
  }
  const deleteItem = item => {
    const newList = store.state.payment.cartList.filter(v => v !== String(item.id))
    store.dispatch('payment/updateCartList', newList)
    getSum()
  }
  const pay = () => {
    store.dispatch('payment/updateCartList', [])
  }
  const confirm = () => {
    const { uid } = store.state.userBasic
    // router.push({ path: '/cart/payment' })
    if (uid === '') router.push({ path: '/login', query: { type: 'student', met: 'cart' } })
    else router.push({ path: '/cart/payment' })
  }

  return {
    dataList,
    total,
    discount,
    getSum,
    deleteItem,
    pay,
    confirm,
  }
}
